<template>
  <div><span>You did it!</span></div>
</template>

<script>
export default {
  name: "Success"
};
</script>

<style></style>
