import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  beforeCreate() {
    const userToken = localStorage.getItem("user");
    if (userToken) {
      const token = JSON.parse(userToken);
      this.$store.commit("SET_TOKEN", token);
    }
  },
  render: h => h(App)
}).$mount("#app");
